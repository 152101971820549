import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'react-toastify/dist/ReactToastify.css';

import { AppProps } from 'next/dist/shared/lib/router/router';
import Head from 'next/head';
import { ColorModeScript } from 'nextjs-color-mode';
import React from 'react';

import ReactModal from 'react-modal';
import { GlobalStyle } from 'components/GlobalStyles';
import { VendorScripts } from 'components/VendorScripts';
import { AuthProvider } from 'contexts/AuthContext';
import { CartProvider } from 'contexts/CartContext';
import { LoadProvider } from 'contexts/Loader';
import { Analytics } from '@vercel/analytics/react';
import { PoolProvider } from 'contexts/PoolContext';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

function MyApp({ Component, pageProps }: AppProps) {
  ReactModal.setAppElement('#__next');

  const client = new QueryClient();

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link rel="icon" type="image/svg" href="/favicon.png" />

        <link rel="apple-touch-icon" sizes="280x280" href="favicon.png" />
        <link rel="icon" type="image/svg" sizes="39x39" href="favicon.png" />
        <link rel="icon" type="image/svg" sizes="16x16" href="favicon.png" />

        <link href="https://fonts.googleapis.com/css2?family=Rochester&display=swap" rel="stylesheet" />
      </Head>
      <ColorModeScript />
      <GlobalStyle />
      <QueryClientProvider client={client}>
        <LoadProvider>
          <AuthProvider>
              <CartProvider>
                <PoolProvider>
                  <VendorScripts />
                  <Analytics />
                  <Component {...pageProps} />
                </PoolProvider>
              </CartProvider>
          </AuthProvider>
        </LoadProvider>
      </QueryClientProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default MyApp;
