import { AlertCPFAlreadyExists } from 'components/ AlertCPFAlreadyExists';
import Router from 'next/router';
import { parseCookies, setCookie } from 'nookies';
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { api } from 'services/apiClient';

interface User {
  id: string;
  email: string;
  name: string;
  roles: string[];
  avatar: string;
  isVerified?: boolean;
  mother?: boolean;
  cpf?: string;
}

interface SignInCredentials {
  email: string;
  password: string;
  recaptcha: string;
}

interface SignUpCredentials {
  name: string;
  email: string;
  password: string;
  recaptcha: string;
  cpf: string;
  phone?: string;
  mother?: boolean;
}

interface IAuthContextProps {
  signIn(credentials: SignInCredentials): Promise<void>;
  signUp(credentials: SignUpCredentials): Promise<void>;
  isAuthenticated: boolean;
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  handleProfile: () => void;
  signOut: () => void;
  isConfirmedEmail: boolean;
  resendEmail: () => void;
  isResendEmailSucess: boolean;
  setIsResendEmailSucess: Dispatch<SetStateAction<boolean>>;
  resetPasswordCPF: any;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>({} as User);
  const [loading, setLoading] = useState(false);
  const isAuthenticated = !!user.email;
  const [isConfirmedEmail, setConfirmedEmail] = useState<boolean>(true);
  const [isResendEmailSucess, setIsResendEmailSucess] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [resetPasswordCPF, setResetPasswordCPF] = useState<string>('');

  const resendEmail = useCallback(async () => {
    try {
      await api.post('users/verification_token');
      setIsResendEmailSucess(true);
      setTimeout(() => {
        setIsResendEmailSucess(false);
      }, 5000);
    } catch (error: any) {
      console.log(error);
    }
  }, []);

  const handleProfile = useCallback(async () => {
    try {
      api.get<User>('users/profile').then((response) => {
        const { email, id, name, roles, avatar, isVerified, mother} = response.data;
        setUser({ email, id, name, roles, avatar, isVerified, mother});
        setConfirmedEmail(isVerified ?? false);;
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  async function signIn({ email, password, recaptcha }: SignInCredentials) {
    try {
      setLoading(true);
      const { data } = await api.post('auth', {
        email,
        password,
        recaptcha,
      });
      const { accessToken, refreshToken } = data;

      setCookie(undefined, 'meunascimento.token', accessToken, {
        maxAge: 60 * 60 * 24 * 30, // 30 days
        path: '/',
      });

      setCookie(undefined, 'meunascimento.refreshToken', refreshToken, {
        maxAge: 60 * 60 * 24 * 30, // 30 days
        path: '/',
      });

      api.defaults.headers['Authorization'] = `Bearer ${accessToken}`;

      if (accessToken) {
        const response = await api.get('users/profile');
        const { email, id, name, roles, avatar, isVerified } = response.data;
        setUser({ email, id, name, roles, avatar, isVerified});
      }
      Router.push('/profile/user');
    } catch (error: any) {
      if(error.response?.data?.message === 'account refused') {
        toast.error('Não foi possível fazer o login. Usuário com CPF recusado.');
      }
      if (error.response?.data?.message === 'wrong email and/or password') {
        toast.error('Email ou senha inválidos!');
      }
      if (error.response?.data.message === 'Validation failed' && error.message === 'Request failed with status code 400') {
        toast.error('Email e senha são obrigatorios!');
      }
    } finally {
      setLoading(false);
    }
  }

  // GET UTM

  const utmParameters = ['utm_source', 'utm_medium', 'utm_campaign'];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    utmParameters.forEach(utm => {
      const value = urlParams.get(utm);
      if(value) localStorage.setItem(utm, value);
    })
  }, []);

  function getTrafficSource() {
    let trafficSource;

    for(let utm of utmParameters) {
      if(localStorage.getItem(utm)){
        trafficSource = localStorage.getItem(utm);
        break; // take the first UTM parameter available as the traffic source
      }
    }
    return trafficSource;
  }

  async function signUp({ name, email, password, recaptcha, cpf, phone, mother }: SignUpCredentials) {
    try {
      setLoading(true);
      const traffic_source = getTrafficSource();
      const response = await api.post('users', {
        name,
        email,
        password,
        recaptcha,
        document: cpf,
        document_type: 'CPF',
        phone: phone,
        traffic_source,
        mother,
      });
       
      if (response.status === 201) {
        toast.success('Cadastro concluido com sucesso');
        toast.success('Por favor, check seu email para confirmar seu cadastro.');
        setTimeout(() => {
          setLoading(false);
          Router.push('/login');
        }, 5000);
      }

    } catch (error: any) {
      console.log(error);
      if (error.response.data.message === 'email already exists') {
        toast.error('Email já cadastrado!');
      }
      if (error.response.data.message === 'CPF already exists') {
        setModalIsOpen(true); 
        setResetPasswordCPF(cpf);
        return
      }

      if(error.response.data.message === 'invalid CPF'){
        toast.error("CPF inválido!");
      }
    } finally {
      setLoading(false);
    }
  }

  const signOut = useCallback(() => {
    document.cookie = 'meunascimento.token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'meunascimento.refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    window.location.reload();
  }, []);

  useEffect(() => {
    const { 'meunascimento.token': token } = parseCookies();

    if (token) {
      const isValidToken = async () => {
        try {
          handleProfile();
        } catch (error) {
          signOut();
        }
      };
      isValidToken();
    }
  }, [handleProfile, signOut]);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        setLoading,
        handleProfile,
        signUp,
        isAuthenticated,
        loading,
        user,
        setUser,
        signOut,
        isConfirmedEmail,
        resendEmail,
        setIsResendEmailSucess,
        isResendEmailSucess,
        resetPasswordCPF
      }}
    >
      {children}
      {modalIsOpen && (
        <>
          <AlertCPFAlreadyExists resetPasswordCPF={resetPasswordCPF} modalIsOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)} />
        </>
      )}
    </AuthContext.Provider>
  );
}

export function useAuth(): IAuthContextProps {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
