import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react';
import styled from 'styled-components';
import { media } from 'utils/media';

export interface ILoaderContext {
  loader?: boolean;
  setLoader?: Dispatch<SetStateAction<boolean>>;
  setLabel?: Dispatch<SetStateAction<string>>;
}

const LoaderContext = createContext<ILoaderContext>({
  loader: undefined,
  setLoader: undefined,
  setLabel: undefined,
});

export function LoadProvider({ children }: PropsWithChildren<ILoaderContext>) {
  const [loader, setLoader] = useState(false);
  const [label, setLabel] = useState('');

  return (
    <LoaderContext.Provider
      value={{
        loader,
        setLoader,
        setLabel,
      }}
    >
      {children}
      {loader && (
        <Container>
          <div className="loader" />
          {label && <h1>{label}</h1>}
        </Container>
      )}
    </LoaderContext.Provider>
  );
}

export function useLoader(): ILoaderContext {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error('useLoader must be used within an LoaderProvider');
  }
  return context;
}

const Container = styled.div`
  position: fixed;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loader {
    border: 2px solid #fff6e8;
    border-top: 2px solid #174F68;
    border-bottom: 2px solid #f09192;
    border-left: 2px solid #174F68;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }

  & h1 {
    margin-top: 1rem;
    color: #fff6e8;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  ${media('<=tablet')} {
    & h1 {
      font-size: 11pt;
    }
  }
`;
