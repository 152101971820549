import Script from 'next/script';
import { EnvVars } from 'env';

export function GoogleAnalytics() {
  const { GA_TAG_ID } = EnvVars;
  return (
    <>
      <Script defer id="gtag-init" src={`https://www.googletagmanager.com/gtag/js?id=${GA_TAG_ID}`}></Script>
      <Script defer id="gtag-config">
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${GA_TAG_ID}');`}
      </Script>
    </>
  );
}
