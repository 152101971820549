import { createContext, Dispatch, SetStateAction, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { api } from 'services/apiClient';
import { formatarValorParaReais } from 'utils/CurrencyFormat';
import { formatarDataAno } from 'utils/formatDate';
import { useReCAPTCHA } from './ReCAPTCHAContext';

interface CartProps {
  presente: IListPresentsProps[];
  setPresente: Dispatch<SetStateAction<IListPresentsProps[]>>;
  addToCart(item: IListPresentsProps): void;
  handlePurchase(purchaseId: string, type: string): void;
  handlePurchasePix(purchaseId: string, type: string): void;
  handlePurchaseCart(purchaseId: string, type: string, cardToken: string): Promise<void>;
  createOrder(id: string): any;
  increment(id: string): void;
  decrement(id: string): void;
  remove(id: string): void;
  fetchAddedGifts(id: string): void;
  cartTotal: number;
  totalItensInCart: string;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  cpf: string;
  setCpf: Dispatch<SetStateAction<string>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  birthDate: string;
  setBirthDate: Dispatch<SetStateAction<string>>;
  street: string;
  setStreet: Dispatch<SetStateAction<string>>;
  number: string;
  setNumber: Dispatch<SetStateAction<string>>;
  complement: string;
  setComplement: Dispatch<SetStateAction<string>>;
  neighborhood: string;
  setNeighborhood: Dispatch<SetStateAction<string>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  state: string;
  setState: Dispatch<SetStateAction<string>>;
  zipcode: string;
  setZipcode: Dispatch<SetStateAction<string>>;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  stepPayment: number;
  setStepPayment: Dispatch<SetStateAction<number>>;
  installments: number;
  setInstallments: Dispatch<SetStateAction<number>>;
  installmentValue: number;
  setInstallmentValue: Dispatch<SetStateAction<number>>;
  setValorTotal: Dispatch<SetStateAction<number>>;
  valorTotal: number;
  setStatus: Dispatch<SetStateAction<number>>;
  status: number;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  messagePix: string;
  setMessagePix: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  modalInfo: boolean;
  setModalInfo: Dispatch<SetStateAction<boolean>>;
  openModalPagamento: boolean;
  setOpenModalPagamento: Dispatch<SetStateAction<boolean>>;
  dadosBoleto: IPayProps;
  setDadosBoleto: Dispatch<SetStateAction<IPayProps>>;
  dadosPix: IPayPropsPix;
  setDadosPix: Dispatch<SetStateAction<IPayPropsPix>>;
  reset: () => void;
  buttonsDisabled: boolean;
  setButtonsDisabled: Dispatch<SetStateAction<boolean>>;
  listPresent: IListPresentsProps[];
  setListPresent: Dispatch<SetStateAction<IListPresentsProps[]>>;
  setTypePayment: Dispatch<SetStateAction<string>>;
  typePayment: string;
  messageErrorModalInformation: string;
  showModalInformation: boolean;
  setShowModalError: Dispatch<SetStateAction<boolean>>;
  setMessageErrorModalInformation: Dispatch<SetStateAction<string>>;
  showModalInformationError: () => void;
  typeInformation: 'sucess' | 'error';
  addRaflesToCart: (raffles: any) => void;
}

interface IBoletoProps {
  barcode: string;
  dueAt: number;
  line: string;
  pdf: string;
  qrcode: string;
}

export interface IPayProps {
  boleto: IBoletoProps;
  createdAt: number;
  id: string;
  paymentMethod: string;
  referenceId: string;
  splitAmount: number;
  status: string;
  total: number;
  updatedAt: number;
}

interface IPixProps {
  qrcodeUrl: string;
  qrcode: string;
}

export interface IPayPropsPix {
  pix: IPixProps;
  createdAt: number;
  id: string;
  paymentMethod: string;
  referenceId: string;
  splitAmount: number;
  status: string;
  total: number;
  updatedAt: number;
}
interface IListPresentsProps {
  productUrl: string | undefined;
  name: string;
  price: number;
  image: string;
  giftId: string;
  giftType: {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
  quantity: number;
  stock?: number;
  raffleTicket?: {
    id: string;
    status: string;
  };
  splitAmount: number;
}

interface IListPresentsProps {
  name: string;
  price: number;
  image: string;
  giftId: string;
  giftType: {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
  quantity: number;
  splitAmount: number;
  stock?: number;
  raffleTicket?: {
    id: string;
    status: string;
  };
}

const CartContext = createContext<CartProps>({} as CartProps);

export const CartProvider: React.FC = ({ children }) => {
  const [presente, setPresente] = useState<IListPresentsProps[]>([]);
  const [typePayment, setTypePayment] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [birthDate, setBirthDate] = useState('');

  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [step, setStep] = useState(2);
  const [stepPayment, setStepPayment] = useState(1);
  const [installments, setInstallments] = useState(1);
  const [installmentValue, setInstallmentValue] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);
  const [status, setStatus] = useState(0);
  const [message, setMessage] = useState('');
  const [messagePix, setMessagePix] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [openModalPagamento, setOpenModalPagamento] = useState(false);
  const [dadosBoleto, setDadosBoleto] = useState<IPayProps>({} as IPayProps);
  const [dadosPix, setDadosPix] = useState<IPayPropsPix>({} as IPayPropsPix);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [listPresent, setListPresent] = useState<IListPresentsProps[]>([]);
  const { recaptcha, setRefreshRecaptcha } = useReCAPTCHA();
  const [showModalInformation, setShowModalError] = useState<boolean>(false);
  const [messageErrorModalInformation, setMessageErrorModalInformation] = useState<string>('');
  const [typeInformation, setTypeInformation] = useState<'sucess' | 'error'>('error');
  const [isStatusTransitionSucess, setIsStatusTransitionSucess] = useState<boolean>(false);
  const refIsStatusTransition = useRef(isStatusTransitionSucess);

  const showModalInformationError = () => {
    setShowModalError(true);
    setTimeout(() => {
      setShowModalError(false);
    }, 5000);
  };

  const addToCart = useCallback(
    async (present) => {
      const productsExists = presente.find((p) => p.giftId === present.giftId);

      if (productsExists) {
        setPresente(
          presente.map((p) =>
            p.giftId === present.giftId
              ? { ...present, quantity: p.stock && (p.stock > 0 || p.stock === -1) && p.quantity < p.stock ? p.quantity + 1 : p.quantity }
              : p,
          ),
        );
      } else {
        const copy = [...presente];

        copy.push({ ...present, quantity: 1 });
        setPresente(copy);
      }
    },
    [presente],
  );

  const addRaflesToCart = (raffles: any[]) => {
    setPresente(
      raffles.map((raffle: any) => {
        return { ...raffle, quantity: 1 };
      }),
    );
  };

  const increment = useCallback(
    async (id) => {
      const newPresents = presente.map((present) => (present.giftId === id ? { ...present, quantity: present.quantity + 1 } : present));

      setPresente(newPresents);
    },
    [presente],
  );

  const decrement = useCallback(
    async (id) => {
      const newPresents = presente.map((present) => (present.giftId === id ? { ...present, quantity: present.quantity - 1 } : present));

      setPresente(newPresents);
    },
    [presente],
  );

  const remove = useCallback(
    async (id) => {
      const newPresents = presente.filter((present) => present.giftId !== id);

      setPresente(newPresents);
    },
    [presente],
  );

  const cartTotal = useMemo(() => {
    if (openModalPagamento) {
      const total = presente.reduce((accumulator, product) => {
        const productsSubtotal = product.price * product.quantity;
        return accumulator + productsSubtotal;
      }, 0);
      return total;
    }
    return 0;
  }, [openModalPagamento, presente]);

  const totalItensInCart = useMemo(() => {
    const total = presente.reduce((accumulator, product) => {
      const productsQuantity = product.quantity;

      return accumulator + productsQuantity;
    }, 0);

    return formatarValorParaReais(total);
  }, [presente]);

  const handlePurchaseCart = useCallback(
    async (purchaseId: string, type: string, cardToken: string) => {
      try {
        setLoading(true);
        const { data } = await api.post(`purchases/${purchaseId}/pay`, {
          payment_method: type,
          card_token: cardToken,
          installments,
          billing_address: {
            street,
            number,
            complement,
            neighborhood,
            city,
            state,
            zipcode,
          },
          recaptcha,
        });

        if (data.status === 'paid') {
          setButtonsDisabled(true);
          setMessageErrorModalInformation(
            'Estamos processando o seu pagamento e em breve você receberá no e-mail a confirmação. Obrigado!',
          );
          setTypeInformation('sucess');
          showModalInformationError();
          setStatus(200);
          setMessage('Estamos processando o seu pagamento e em breve você receberá no e-mail a confirmação. Obrigado!');
          setLoading(false);
          setModalInfo(true);
          setInterval(() => {
            /* Pagamento do cartão passa aqui */
            window.location.reload();
          }, 5000);
        }

        if (data.status === 'failed') {
          setStatus(400);
          setMessage('Ocorreu um erro na operação, por favor revise seus dados!');
          setLoading(false);
          setModalInfo(true);
        }
      } catch (error: any) {
        if (error?.response?.status === 500) {
          setMessageErrorModalInformation('Ocorreu um erro interno no servidor, tente novamente mais tarde.');
          setTypeInformation('error');
          showModalInformationError();
          setInterval(() => {
            window.location.reload();
          }, 5000);
          return;
        }

        if (error?.message === 'invalid CPF' || 'invalid CPF' === error?.response?.data?.message) {
          setStatus(400);
          setMessageErrorModalInformation('O CPF inserido é inválido. Por favor, verifique e tente novamente!');
          setTypeInformation('error');
          showModalInformationError();
          return;
        }

        if (error?.message === 'card_token is required' || 'card_token is required' === error?.response?.data?.message) {
          setStatus(400);
          setMessageErrorModalInformation(
            'Ocorreu um erro com ao processar o pagamento. Verifique se os dados do cartão foram inseridos corretamente e se há saldo disponível!',
          );
          setTypeInformation('error');
          showModalInformationError();
          return;
        }

        if (error?.message === 'billingAddress is required' || 'billingAddress is required' === error?.response?.data?.message) {
          setStatus(400);
          setMessageErrorModalInformation(
            'Endereço de cobrança não foi fornecido corretamente. Por favor, revise seus dados e tente novamente!',
          );
          setTypeInformation('error');
          showModalInformationError();
          return;
        }

        if (error?.message === 'account not found' || 'account not found' === error?.response?.data?.message) {
          setMessageErrorModalInformation('Ocorreu um erro na operação, conta de recebimento não encontrada!');
          setTypeInformation('error');
          showModalInformationError();
          return;
        }

        if (error?.message === 'purchase not found' || 'purchase not found' === error?.response?.data?.message) {
          setStatus(404);
          setMessageErrorModalInformation('Ocorreu um erro na operação, compra não encontrada!');
          setTypeInformation('error');
          showModalInformationError();
          return;
        }
        setMessageErrorModalInformation('Ocorreu um erro na operação!');
        setTypeInformation('error');
        showModalInformationError();
        return error;
      } finally {
        setLoading(false);
        setButtonsDisabled(false);
        setRefreshRecaptcha(Date.now());
      }
    },
    [city, complement, installments, neighborhood, number, recaptcha, setRefreshRecaptcha, state, street, zipcode],
  );

  const handlePurchase = useCallback(
    async (purchaseId: string, type: string) => {
      try {
        setLoading(true);

        const { data } = await api.post<IPayProps>(`purchases/${purchaseId}/pay`, {
          payment_method: type,
          billing_address: {
            street,
            number,
            complement,
            neighborhood,
            city,
            state,
            zipcode,
          },
          recaptcha,
        });

        if (data.status === 'pending') {
          setButtonsDisabled(true);
          setStatus(200);
          setDadosBoleto(data);
          setLoading(false);
          return;
        }

        if (data.status === 'failed') {
          setMessage('Ocorreu um erro na operação, por favor revise seus dados!');
          setLoading(false);
          setStatus(400);
          return;
        }
      } catch (error: any) {
        if (error?.response?.status === 500) {
          setMessageErrorModalInformation('Ocorreu um erro interno no servidor, tente novamente mais tarde.');
          setTypeInformation('error');
          showModalInformationError();
          setInterval(() => {
            window.location.reload();
          }, 5000);
          return;
        }

        if (error?.message === 'account not found' || 'account not found' === error?.response?.data?.message) {
          setMessageErrorModalInformation('Ocorreu um erro na operação, conta de recebimento não encontrada!');
          setTypeInformation('error');
          showModalInformationError();
          return;
        }

        if (error?.message === 'purchase not found' || 'purchase not found' === error?.response?.data?.message) {
          setStatus(404);
          setMessageErrorModalInformation('Ocorreu um erro na operação, compra não encontrada!');
          setTypeInformation('error');
          showModalInformationError();

          return;
        }

        setMessageErrorModalInformation('Ocorreu um erro na operação!');
        setTypeInformation('error');
        showModalInformationError();
        return error;
      } finally {
        setButtonsDisabled(false);
        setRefreshRecaptcha(Date.now());
      }
    },
    [city, complement, neighborhood, number, recaptcha, setRefreshRecaptcha, state, street, zipcode],
  );

  async function statusPayment(id: string) {
    try {
      const { data } = await api.get(`purchases/${id}/transaction`);
      if (data.status === 'paid' && !refIsStatusTransition.current) {
        setMessageErrorModalInformation('Estamos processando o seu pagamento e em breve você receberá no e-mail a confirmação. Obrigado!');
        setTypeInformation('sucess');
        setIsStatusTransitionSucess(true);
        showModalInformationError();
        setLoading(true);
        setStatus(200);
        setMessage('Estamos processando o seu pagamento e em breve você receberá no e-mail a confirmação. Obrigado!');
        setLoading(false);
        setModalInfo(true);
        setTimeout(() => {
          /*Ao confirmar o pagamento no pix ele passa aqui*/
          window.location.reload();
        }, 5000);
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        if (error?.response?.data?.message === 'purchaseNotFound') {
          setMessageErrorModalInformation('Compra não realizada. Por favor, verifique os dados da compra e tente novamente.');
        } else if (error?.response?.data?.message === 'transactionNotFound') {
          setMessageErrorModalInformation('Transação não realizada. Por favor, verifique os dados da compra e tente novamente.');
        } else {
          setMessageErrorModalInformation('Ocorreu um erro na sua solicitação. Por favor, tente novamente mais tarde.');
        }
        setTypeInformation('error');
        showModalInformationError();
        return;
      }
    }
  }

  const handlePurchasePix = useCallback(
    async (purchaseId: string, type: string) => {
      try {
        setLoading(true);

        const { data } = await api.post<IPayPropsPix>(`purchases/${purchaseId}/pay`, {
          payment_method: type,
          recaptcha,
        });
        if (data.status === 'pending') {
          setButtonsDisabled(true);
          setDadosPix(data);
          setLoading(false);
          setInterval(() => {
            statusPayment(purchaseId);
          }, 5000);
        }

        if (data.status === 'failed') {
          setMessagePix('Ocorreu um erro na operação, por favor revise seus dados!');
          setLoading(false);
          setStatus(400);
          return;
        }
      } catch (error: any) {
        if (error?.response?.status === 500) {
          setMessageErrorModalInformation('Ocorreu um erro interno no servidor, tente novamente mais tarde.');
          setTypeInformation('error');
          showModalInformationError();
          setInterval(() => {
            window.location.reload();
          }, 5000);
          return;
        }

        if (error?.message === 'account not found' || 'account not found' === error?.response?.data?.message) {
          setMessageErrorModalInformation('Ocorreu um erro na operação, conta de recebimento não encontrada!');
          showModalInformationError();
          return;
        }
        if (error?.message === 'purchase not found' || 'purchase not found' === error?.response?.data?.message) {
          setStatus(404);
          setMessageErrorModalInformation('Ocorreu um erro na operação, compra não encontrada!');
          showModalInformationError();
          return;
        }
        setMessageErrorModalInformation('Ocorreu um erro na operação!');
        showModalInformationError();
        return error;
      } finally {
        setRefreshRecaptcha(Date.now());
        setButtonsDisabled(false);
      }
    },
    [recaptcha, setRefreshRecaptcha],
  );

  const createOrder = useCallback(
    async (eventoId) => {
      const items = presente.map((item) => ({ gift_id: item.giftId, quantity: item.quantity }));
      try {
        const { data } = await api.post(`purchases`, {
          party_id: eventoId,
          buyer: {
            name,
            email,
            cpf: cpf.replaceAll('-', '').replaceAll('.', ''),
            phone,
            birth_date: formatarDataAno(birthDate),
          },
          items,
        });

        return data;
      } catch (error: any) {
        console.log(error?.response?.data?.message);
        if (error?.message === 'gift not found' || 'gift not found' === error?.response?.data?.message) {
          setModalInfo(true);
          setMessage('Ocorreu um erro na operação, presente não encontrado!');
          setMessageErrorModalInformation('Ocorreu um erro na operação, presente não encontrado!');
          showModalInformationError();
          return;
        }
        if (error?.message === 'your purchase is empty' || 'your purchase is empty' === error?.response?.data?.message) {
          setModalInfo(true);
          setMessage('Ocorreu um erro na operação, sua lista de presentes esta vazia!');
          setMessageErrorModalInformation('Ocorreu um erro na operação, sua lista de presentes esta vazia!');
          showModalInformationError();
          return;
        }
        if (error?.response?.data?.message) {
          setModalInfo(true);
          setMessage('Ocorreu um erro na operação, por favor revise seus dados!');
          setMessageErrorModalInformation('Ocorreu um erro na operação, por favor revise seus dados!');
          showModalInformationError();
          return;
        }
        return error?.data?.status;
      }
    },
    [birthDate, cpf, email, name, phone, presente],
  );

  const fetchAddedGifts = useCallback(async (eventoId) => {
    try {
      const { data } = await api.get<{ values: IListPresentsProps[] }>(`parties/${eventoId}/gift_lists?limit=999`);
      setListPresent(data.values);
    } catch (error) {
      console.log(error);
    }
  }, []);

  function reset() {
    window.location.reload();
    setPresente([]);
    setName('');
    setEmail('');
    setCpf('');
    setPhone('');
    setBirthDate('');
    setStreet('');
    setNumber('');
    setComplement('');
    setNeighborhood('');
    setCity('');
    setState('');
    setZipcode('');
    setValorTotal(0);
    setStatus(0);
    setStep(1);
    setStepPayment(1);
    setMessage('');
    setLoading(false);
    setModalInfo(false);
    setStatus(0);
    setOpenModalPagamento(false);
    setDadosBoleto({} as IPayProps);
    setDadosPix({} as IPayPropsPix);
    setButtonsDisabled(false);
  }
  return (
    <CartContext.Provider
      value={{
        presente,
        setPresente,
        addToCart,
        increment,
        decrement,
        remove,
        cartTotal,
        totalItensInCart,
        name,
        setName,
        email,
        setEmail,
        cpf,
        listPresent,
        setListPresent,
        setCpf,
        phone,
        setPhone,
        birthDate,
        setBirthDate,
        handlePurchaseCart,
        handlePurchase,
        handlePurchasePix,
        dadosBoleto,
        setDadosBoleto,
        dadosPix,
        setDadosPix,
        createOrder,
        street,
        setStreet,
        fetchAddedGifts,
        number,
        setNumber,
        reset,
        complement,
        setComplement,
        neighborhood,
        setNeighborhood,
        city,
        setCity,
        state,
        setState,
        zipcode,
        setZipcode,
        step,
        setStep,
        stepPayment,
        setStepPayment,
        installments,
        setInstallments,
        setInstallmentValue,
        installmentValue,
        valorTotal,
        setValorTotal,
        status,
        setStatus,
        message,
        setMessage,
        messagePix,
        setMessagePix,
        loading,
        setLoading,
        modalInfo,
        setModalInfo,
        openModalPagamento,
        setOpenModalPagamento,
        buttonsDisabled,
        setButtonsDisabled,
        typePayment,
        setTypePayment,
        messageErrorModalInformation,
        showModalInformation,
        setShowModalError,
        setMessageErrorModalInformation,
        showModalInformationError,
        typeInformation,
        addRaflesToCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export function useCart(): CartProps {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('useEvento must be used within an useEvento');
  }

  return context;
}
