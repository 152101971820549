import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { api } from 'services/apiClient';

import { useReCAPTCHA } from './ReCAPTCHAContext';
import { Paginate, Supportes } from 'templates/pool/types';
import { useLoader } from './Loader';

interface PoolProps {
  handlePayment(poolId: string, type: 'pix' | 'credit_card' | 'boleto', cardToken?: string): Promise<void>;
  setAmount: Dispatch<SetStateAction<string>>;
  amount: string;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  anonymous: boolean;
  setAnonymous: Dispatch<SetStateAction<boolean>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  cpf: string;
  setCpf: Dispatch<SetStateAction<string>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  birthDate: string;
  setBirthDate: Dispatch<SetStateAction<string>>;
  street: string;
  setStreet: Dispatch<SetStateAction<string>>;
  number: string;
  setNumber: Dispatch<SetStateAction<string>>;
  complement: string;
  setComplement: Dispatch<SetStateAction<string>>;
  neighborhood: string;
  setNeighborhood: Dispatch<SetStateAction<string>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  state: string;
  setState: Dispatch<SetStateAction<string>>;
  zipcode: string;
  setZipcode: Dispatch<SetStateAction<string>>;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  stepPayment: number;
  setStepPayment: Dispatch<SetStateAction<number>>;
  installments: number;
  setInstallments: Dispatch<SetStateAction<number>>;
  installmentValue: number;
  setInstallmentValue: Dispatch<SetStateAction<number>>;
  setValorTotal: Dispatch<SetStateAction<number>>;
  valorTotal: number;
  setStatus: Dispatch<SetStateAction<number>>;
  status: number;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  messagePix: string;
  setMessagePix: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  modalInfo: boolean;
  setModalInfo: Dispatch<SetStateAction<boolean>>;
  openModalPagamento: boolean;
  setOpenModalPagamento: Dispatch<SetStateAction<boolean>>;
  dadosBoleto: IPayProps;
  setDadosBoleto: Dispatch<SetStateAction<IPayProps>>;
  dadosPix: IPayPropsPix;
  setDadosPix: Dispatch<SetStateAction<IPayPropsPix>>;
  buttonsDisabled: boolean;
  setButtonsDisabled: Dispatch<SetStateAction<boolean>>;
  setTypePayment: Dispatch<SetStateAction<string>>;
  typePayment: string;
  messageErrorModalInformation: string;
  showModalInformation: boolean;
  setShowModalError: Dispatch<SetStateAction<boolean>>;
  setMessageErrorModalInformation: Dispatch<SetStateAction<string>>;
  supportes: Supportes[];
  setSupportes: Dispatch<SetStateAction<Supportes[]>>;
  showModalInformationError: () => void;
  typeInformation: 'sucess' | 'error';
  fetchAddedSupporters(id: string, page?: number): void;
  handleNextSupportesPage?(id: string): void;
  handlePreviewSupportesPage?(id: string): void;
  totalSupportes?: number;
  totalSupportesPages?: number;
  currentPage?: number;
  cardNumber: string;
  setCardNumber: Dispatch<SetStateAction<string>>;
  cardFlag: string;
  setCardFlag: Dispatch<SetStateAction<string>>;
  cardName: string;
  setCardName: Dispatch<SetStateAction<string>>;
  expirationMonth: string;
  setExpirationMonth: Dispatch<SetStateAction<string>>;
  expirationYear: string;
  setExpirationYear: Dispatch<SetStateAction<string>>;
  securityCode: string;
  setSecurityCode: Dispatch<SetStateAction<string>>;
}

interface IBoletoProps {
  barcode: string;
  dueAt: number;
  line: string;
  pdf: string;
  qrcode: string;
}

export interface IPayProps {
  boleto: IBoletoProps;
  createdAt: number;
  id: string;
  paymentMethod: string;
  referenceId: string;
  splitAmount: number;
  status: string;
  total: number;
  updatedAt: number;
}

interface IPixProps {
  qrcodeUrl: string;
  qrcode: string;
}

export interface IPayPropsPix {
  pix: IPixProps;
  createdAt: number;
  id: string;
  paymentMethod: string;
  referenceId: string;
  splitAmount: number;
  status: string;
  total: number;
  updatedAt: number;
}

export const PoolContext = createContext<PoolProps>({} as PoolProps);

export const PoolProvider: React.FC = ({ children }) => {
  const [amount, setAmount] = useState('0');

  const [typePayment, setTypePayment] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [anonymous, setAnonymous] = useState(false);

  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [step, setStep] = useState(2);
  const [stepPayment, setStepPayment] = useState(1);
  const [installments, setInstallments] = useState(1);
  const [installmentValue, setInstallmentValue] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);
  const [status, setStatus] = useState(0);
  const [message, setMessage] = useState('');
  const [messagePix, setMessagePix] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [openModalPagamento, setOpenModalPagamento] = useState(false);
  const [dadosBoleto, setDadosBoleto] = useState<IPayProps>({} as IPayProps);
  const [dadosPix, setDadosPix] = useState<IPayPropsPix>({} as IPayPropsPix);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [supportes, setSupportes] = useState<Supportes[]>([]);
  const [cardNumber, setCardNumber] = useState('');
  const [cardFlag, setCardFlag] = useState<string>('');
  const [cardName, setCardName] = useState('');
  const [cardDocument, setCardDocument] = useState('');
  const [expirationMonth, setExpirationMonth] = useState('');
  const [expirationYear, setExpirationYear] = useState('');
  const [securityCode, setSecurityCode] = useState('');

  const { recaptcha, setRefreshRecaptcha } = useReCAPTCHA();
  const [showModalInformation, setShowModalError] = useState<boolean>(false);
  const [messageErrorModalInformation, setMessageErrorModalInformation] = useState<string>('');
  const [typeInformation, setTypeInformation] = useState<'sucess' | 'error'>('error');
  const [isStatusTransitionSucess, setIsStatusTransitionSucess] = useState<boolean>(false);
  const refIsStatusTransition = useRef(isStatusTransitionSucess);

  const { setLoader } = useLoader();

  const [totalSupportes, setTotalSupportes] = useState(0);
  const [totalSupportesPages, setTotalSupportesPages] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const [sizePage, setSizePage] = useState(0);

  const handleResize = (e: any) => {
    setSizePage(window.innerWidth);
  };

  useEffect(() => {
    setSizePage(window.innerWidth);
    window.addEventListener('resize', handleResize);
  }, []);

  const showModalInformationError = () => {
    setShowModalError(true);
    setTimeout(() => {
      setShowModalError(false);
    }, 5000);
  };

  async function statusPayment(supporterId: string) {
    try {
      const { data } = await api.get(`supporters/${supporterId}`);

      if (data.status === 'paid' && !refIsStatusTransition.current) {
        setMessageErrorModalInformation('Estamos processando o seu pagamento e em breve você receberá no e-mail a confirmação. Obrigado!');
        setTypeInformation('sucess');
        setIsStatusTransitionSucess(true);
        showModalInformationError();
        setLoading(true);
        setStatus(200);
        setMessage('Estamos processando o seu pagamento e em breve você receberá no e-mail a confirmação. Obrigado!');
        setLoading(false);
        setModalInfo(true);
        setTimeout(() => {
          /*Ao confirmar o pagamento no pix ele passa aqui*/
          window.location.reload();
        }, 5000);
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        if (error?.response?.data?.message === 'purchaseNotFound') {
          setMessageErrorModalInformation('Compra não realizada. Por favor, verifique os dados da compra e tente novamente.');
        } else if (error?.response?.data?.message === 'transactionNotFound') {
          setMessageErrorModalInformation('Transação não realizada. Por favor, verifique os dados da compra e tente novamente.');
        } else {
          setMessageErrorModalInformation('Ocorreu um erro na sua solicitação. Por favor, tente novamente mais tarde.');
        }
        setTypeInformation('error');
        showModalInformationError();
        return;
      }
    }
  }

  const handlePayment = async (poolId: string, type: 'pix' | 'credit_card' | 'boleto', cardToken?: string) => {
    try {
      setLoading(true);
      let amountWithFees = (parseFloat(amount) * 100) / (1 - 0.065);
      if (type === 'boleto') amountWithFees -= 150; //Taxa do boleto de 4,99 para 3,49 remover 1,50
      const { data } = await api.post(`pools/${poolId}/supporters`, {
        name,
        email,
        document: cpf,
        phone: phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '').slice(3, 13),
        amount: Number(amountWithFees.toFixed().toString().replace('.', '')),
        payment_method: type,
        card_token: cardToken,
        anonymous,
        billing_address: {
          street: 'Mariano Procópio',
          number: '25',
          complement,
          neighborhood: 'São José',
          city: 'Barbacena',
          state: 'MG',
          zipcode: '36205-048',
        },
        recaptcha,
      });

      switch (type) {
        case 'boleto':
          if (data?.transaction?.status === 'pending') {
            setButtonsDisabled(true);
            setStatus(200);
            setDadosBoleto(data.transaction);
            setLoading(false);
          }
          break;

        case 'pix':
          if (data?.transaction?.status === 'pending') {
            setButtonsDisabled(true);
            setDadosPix(data.transaction);
            setLoading(false);
            setInterval(() => {
              statusPayment(data.supporter.id);
            }, 5000);
          }
          break;

        default:
          if (data?.transaction?.status === 'paid') {
            setButtonsDisabled(true);
            setMessageErrorModalInformation(
              'Estamos processando o seu pagamento e em breve você receberá no e-mail a confirmação. Obrigado!',
            );
            setTypeInformation('sucess');
            showModalInformationError();
            setStatus(200);
            setMessage('Estamos processando o seu pagamento e em breve você receberá no e-mail a confirmação. Obrigado!');
            setLoading(false);
            setModalInfo(true);
            setStepPayment(1);
            setInterval(() => {
              /* Pagamento do cartão passa aqui */
              window.location.reload();
            }, 5000);
          }
          break;
      }

      if (data.status === 'failed' || data?.transaction?.status === 'failed') {
        setStatus(400);
        setMessage('Ocorreu um erro na operação, por favor revise seus dados!');
        setLoading(false);
        setModalInfo(true);
      }
    } catch (error: any) {
      if (error?.response?.status === 500) {
        setMessageErrorModalInformation('Ocorreu um erro interno no servidor, tente novamente mais tarde.');
        setTypeInformation('error');
        showModalInformationError();
        setInterval(() => {
          window.location.reload();
        }, 5000);
        return;
      }

      if (error?.message === 'invalid CPF' || 'invalid CPF' === error?.response?.data?.message) {
        setStatus(400);
        setMessageErrorModalInformation('O CPF inserido é inválido. Por favor, verifique e tente novamente!');
        setTypeInformation('error');
        showModalInformationError();
        return;
      }

      if (error?.message === 'card_token is required' || 'card_token is required' === error?.response?.data?.message) {
        setStatus(400);
        setMessageErrorModalInformation(
          'Ocorreu um erro com ao processar o pagamento. Verifique se os dados do cartão foram inseridos corretamente e se há saldo disponível!',
        );
        setTypeInformation('error');
        showModalInformationError();
        return;
      }

      if (error?.message === 'billingAddress is required' || 'billingAddress is required' === error?.response?.data?.message) {
        setStatus(400);
        setMessageErrorModalInformation(
          'Endereço de cobrança não foi fornecido corretamente. Por favor, revise seus dados e tente novamente!',
        );
        setTypeInformation('error');
        showModalInformationError();
        return;
      }

      if (error?.message === 'account not found' || 'account not found' === error?.response?.data?.message) {
        setMessageErrorModalInformation('Ocorreu um erro na operação, conta de recebimento não encontrada!');
        setTypeInformation('error');
        showModalInformationError();
        return;
      }

      if (error?.message === 'purchase not found' || 'purchase not found' === error?.response?.data?.message) {
        setStatus(404);
        setMessageErrorModalInformation('Ocorreu um erro na operação, compra não encontrada!');
        setTypeInformation('error');
        showModalInformationError();
        return;
      }
      setMessageErrorModalInformation('Ocorreu um erro na operação!');
      setTypeInformation('error');
      showModalInformationError();
      fetchAddedSupporters(poolId);
      throw error;
    } finally {
      setLoading(false);
      setButtonsDisabled(false);
      setRefreshRecaptcha?.(Date.now());
    }
  };

  const fetchAddedSupporters = useCallback(
    (pooId: string, page = 1) => {
      if (!(sizePage > 0)) return;
      setLoader?.(true);
      api
        .get<Paginate<Supportes>>(`pools/${pooId}/supporters?limit=${sizePage < 467 ? 10 : 20}&page=${page}`)
        .then(({ data }) => {
          setSupportes(data.values ?? []);
          setTotalSupportes(data.total ?? 0);
          setCurrentPage(page);
          setTotalSupportesPages(data.pages);
        })
        .finally(() => {
          setLoader?.(false);
        });
    },
    [setLoader, sizePage],
  );

  const handleNextSupportesPage = useCallback(
    (pooId: string) => {
      const next = currentPage + 1;
      if (totalSupportes >= next) fetchAddedSupporters(pooId, next);
    },
    [currentPage, fetchAddedSupporters, totalSupportes],
  );

  const handlePreviewSupportesPage = useCallback(
    (pooId: string) => {
      const preview = currentPage - 1;
      if (totalSupportes > preview) fetchAddedSupporters(pooId, preview);
    },
    [currentPage, fetchAddedSupporters, totalSupportes],
  );

  return (
    <PoolContext.Provider
      value={{
        currentPage,
        totalSupportes,
        totalSupportesPages,
        cardFlag,
        cardName,
        cardNumber,
        expirationMonth,
        expirationYear,
        securityCode,
        setCardFlag,
        setCardName,
        setCardNumber,
        setExpirationMonth,
        setExpirationYear,
        setSecurityCode,
        supportes,
        setSupportes,
        amount,
        setAmount,
        name,
        setName,
        anonymous,
        setAnonymous,
        email,
        setEmail,
        cpf,
        setCpf,
        phone,
        setPhone,
        birthDate,
        setBirthDate,
        handlePayment,
        dadosBoleto,
        setDadosBoleto,
        dadosPix,
        setDadosPix,
        street,
        setStreet,
        number,
        setNumber,
        complement,
        setComplement,
        neighborhood,
        setNeighborhood,
        city,
        setCity,
        state,
        setState,
        zipcode,
        setZipcode,
        step,
        setStep,
        stepPayment,
        setStepPayment,
        valorTotal,
        setValorTotal,
        status,
        setStatus,
        message,
        setMessage,
        messagePix,
        setMessagePix,
        loading,
        setLoading,
        modalInfo,
        setModalInfo,
        openModalPagamento,
        setOpenModalPagamento,
        buttonsDisabled,
        setButtonsDisabled,
        typePayment,
        setTypePayment,
        messageErrorModalInformation,
        showModalInformation,
        setShowModalError,
        setMessageErrorModalInformation,
        showModalInformationError,
        typeInformation,
        installments,
        setInstallments,
        installmentValue,
        setInstallmentValue,
        fetchAddedSupporters,
        handleNextSupportesPage,
        handlePreviewSupportesPage,
      }}
    >
      {children}
    </PoolContext.Provider>
  );
};

export function usePool(): PoolProps {
  const context = useContext(PoolContext);

  if (!context) {
    throw new Error('useEvento must be used within an useEvento');
  }

  return context;
}
